export const VOLUNTEERS_LIST = 'VOLUNTEER_LIST';
export const VOLUNTEERS_LIST_LOADING = 'VOLUNTEER_LIST_LOADING';
export const VOLUNTEERS_LIST_SUCCESS = 'VOLUNTEER_LIST_SUCCESS';
export const VOLUNTEERS_LIST_ERROR = 'VOLUNTEER_LIST_ERROR';

export const VOLUNTEER_SIGN_UP = "VOLUNTEER_SIGN_UP";
export const VOLUNTEER_SIGN_UP_LOADING = "VOLUNTEER_SIGN_UP_LOADING";
export const VOLUNTEER_SIGN_UP_SUCCESS = "VOLUNTEER_SIGN_UP_SUCCESS";
export const VOLUNTEER_SIGN_UP_ERROR = "VOLUNTEER_SIGN_UP_ERROR";

export const VERIFY_VOLUNTEER = "VERIFY_VOLUNTEER";
export const VERIFY_VOLUNTEER_LOADING = "VERIFY_VOLUNTEER_LOADING";
export const VERIFY_VOLUNTEER_SUCCESS = "VERIFY_VOLUNTEER_SUCCESS";
export const VERIFY_VOLUNTEER_ERROR = "VERIFY_VOLUNTEER_ERROR";